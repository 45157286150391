/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;

  overflow-y: scroll;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.app {
  padding-top: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1 {
  margin-bottom: 2rem;
}

p {
  margin-bottom: 1rem;
}

.popover {
  position: 'absolute';
  top: 25px;
  width: 300px;
  max-width: 300px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.not-actionable {
  opacity: 0.4;
}

/* Debug focus! */

/*
*:focus {
  background: aqua;
  color: blueviolet;
}
*/

label + small.form-text {
  margin-top: -5px;
  margin-bottom: 10px;
}
.form-group {
  margin-bottom: 30px;
}
textarea {
  min-height: 180px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  font-style: italic;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-style: italic;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-style: italic;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-style: italic;
}

/* Graph Styles */

.highlight-circle {
  fill: rgb(160, 220, 255) !important;
}

.highlight-target {
  stroke: red;
}

.highlight-source {
  stroke: green;
}

.dim {
  opacity: 0.6;
}

.text-decoration-underline {
  text-decoration: underline;
}
